import React, { useState, useRef, useEffect } from 'react'
import { el, enGB } from 'date-fns/locale'
import htmlDecode from "html-entities-decoder";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import Calendar from "./../../images/calendar.svg"
import folderIcon from "./../../images/folder-regular.svg";
import { format } from 'date-fns'


import "./Contribution.css";

function OurBlog(props) {
    const data = props.data;

    console.log(data)

    return (
        <div className="py-16 md:py-32 m-auto container-cs text-center px-4 md:px-0">
            {props.lang.toLowerCase() === "el"
                ?
                <h2 className="dk-title">
                    <span>ΤΕΛΕΥΤΑΙΑ</span>
                    <span>ΝΕΑ</span>
                </h2>
                :
                <h2 className="dk-title">
                    <span>LATEST</span>
                    <span>NEWS</span>
                </h2>
            }

            <div className="flex flex-wrap justify-center pt-8">
                {data.nodes.map((post, i) => {
                    return (
                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                            <Link to={`${props.lang === "EL" ? "/blog/" : "/" + props.lang.toLowerCase() + "/blog/"}${post.slug}/`}>
                                <div className="relative bp-article" style={{ boxShadow: "none" }}>
                                    <div className="blog-f-img">
                                        {(post.featuredImage && post.featuredImage.node && post.featuredImage.node?.localFile?.childImageSharp?.gatsbyImageData) && (
                                            <GatsbyImage
                                                image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                                alt={post.featuredImage.node.altText}
                                                className="h-full w-full bp-img-wrapper"
                                                loading="lazy" />
                                        )}
                                    </div>
                                    <div className="  pt-2 bp-info">
                                        <h2 style={{ height: "74px", overflow: "hidden" }}>{htmlDecode(post.title)}</h2>
                                    </div>
                                    <div className="w-full z-10">
                                        <div className="flex  blog-info pt-2">
                                            <div className="flex justify-center">
                                                <div>
                                                    <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                                                </div>
                                                <div className="blog-date">{props.lang === "EL" ? format(new Date(post.date), 'dd MMMM, yyyy', { locale: el }) : format(new Date(post.date), 'dd MMMM, yyyy', { locale: enGB })}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pt-2">
                                        <Link to={props.lang === "EL" ? `/blog/${(post.categories.nodes[0] && post.categories.nodes[0].slug)}` : `/en/blog/${(post.categories.nodes[0] && post.categories.nodes[0].slug)}`} className="flex items-center">
                                            <div>
                                                <img src={folderIcon} style={{ width: "15px" }} />
                                            </div>
                                            <span className="pl-2 blog-date">
                                                {(post.categories.nodes[0] && post.categories.nodes[0].name)}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="pt-8">
                                        <Link className="post-btn-readmore2" to={`${props.lang === "EL" ? "/blog/" : "/" + props.lang.toLowerCase() + "/blog/"}${post.slug}/`}>
                                            {props.lang === "EL" ? "Διαβάστε Περισσότερα" : "Read More"}
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                        </article>
                    );
                })}

                <div className="w-full mt-8">
                    <div className="main-btn-container">
                        <Link to={props.lang.toLowerCase() === "el" ? "/blog" : `/${props.lang.toLowerCase()}/blog`} className="main-button-blue mt-4 md:mt-0">
                            {props.lang.toLowerCase() === "el"
                                ?
                                "Περισσότερα Νέα"
                                :
                                "More News"
                            }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurBlog;
