import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./CoreValues.css";


function CoreValues(props) {
    const data = props.data;
    console.log(data)

    return (
        <div className="flex flex-wrap core-values">

            <div className="w-full" style={{ display: "grid" }}>
                <GatsbyImage
                    className="block my-cs-grid"
                    imgClassName="darken"
                    alt={data.background.altText}
                    loading={"eager"}
                    image={data.background.localFile.childImageSharp.gatsbyImageData}
                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    <div className="block container px-4 py-4 md:py-0">
                        <h2 className="dk-title-action">
                            <span>{data.title1}</span>
                            <span>{data.title2}</span>
                        </h2>
                        <p className="lead-action py-2">{data.content}</p>

                        <div className="flex flex-wrap justify-center mt-8">
                            {(data.points && data.points.length > 0) && data.points.map((item, i) => (
                                <div key={`contribution-${i}`} className="w-1/2 md:w-1/4 text-center text-white">
                                    <div>
                                        <GatsbyImage
                                            image={item.icon.localFile.childImageSharp.gatsbyImageData}
                                            alt={item.icon.altText}
                                            className=" m-auto"
                                        />
                                    </div>
                                    <div className="action-number">
                                        {item.number}
                                    </div>
                                    <div className="action-tt">
                                        {item.title}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CoreValues;

export const query = graphql`fragment OurActionFragment on WpPage_Homepageacf {
    ourAction{
        title1
        title2
        content
        background{
            altText
            sourceUrl
            localFile {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                }
            }
        }
        points{
            title
            number
            icon{
                altText
                sourceUrl
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 50, quality: 100, placeholder: NONE, layout: FIXED)
                    }
                }
            }
        }
    }
}
`