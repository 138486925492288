import React, { useState, useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./Contribution.css";
import Loadable from "@loadable/component"


const LoadableBuyButton = Loadable(() => import("./AnimatedNumberJs"))


function Contribution(props) {
    const isSSR = typeof window === "undefined"
    const hiddenRef = useRef();
    const [isVisible, setIsVisible] = useState(false)
    const data = props.data;

    useEffect(() => {
        if (!isSSR) {
            window.addEventListener('scroll', scrollHandler);
            return () => window.removeEventListener('scroll', scrollHandler);
        }

    }, []);

    const scrollHandler = () => {

        if (window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop)
            setIsVisible(true)

    }

    console.log(data)
    return (
        <div className="py-16 md:py-32 m-auto container-cs text-center px-4 md:px-0">
            <h2 className="dk-title">
                <span>{data.title1}</span>
                <span>{data.title2}</span>
            </h2>
            <p className="lead py-2">{data.content}</p>
            <div className="flex flex-wrap justify-center">
                {(data.points && data.points.length > 0) && data.points.map((item, i) => (
                    <div key={`contribution-${i}`} className="w-full md:w-1/2 contr-points pt-6 md:pt-4">
                        <div>
                            <GatsbyImage
                                image={item.image.localFile.childImageSharp.gatsbyImageData}
                                alt={item.image.altText}
                                className=" m-auto"
                            />
                        </div>
                        <div ref={hiddenRef}>
                            {isVisible
                                ?
                                <LoadableBuyButton number={item.number} />
                                :
                                <div></div>
                            }
                        </div>

                        {/* <div className='flex justify-center items-center'>
                            <AnimatedNumber
                                component="text"
                                value={item.number}
                                style={{
                                    transition: '0.8s ease-out',
                                    fontSize: 48,
                                    transitionProperty:
                                        'background-color, color, opacity'
                                }}
                                formatValue={n => parseInt(n)}
                                duration={2000}
                            />
                            <div className='plus-st'>
                                +
                            </div>
                        </div> */}
                        <div className='font-bold' dangerouslySetInnerHTML={{ __html: item.content }} />

                    </div>
                ))}
            </div>
        </div>
    );
}

export default Contribution;

export const query = graphql`fragment ContributionFragment on WpPage_Homepageacf {
    contribution{
        title1
        title2
        content
        points{
          content
          number
          image{
            altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 70, quality: 100, placeholder: NONE, layout: FIXED)
                }
              }
          }
        }
      }
}
`