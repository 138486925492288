import { Link } from 'gatsby';
import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import "./Newsletter.css";


function Newsletter({ servicesTitle, servicesSectionName, servicesDescription, servicesReadMore, contactTerms, lang }) {
    const [terms, setTerms] = useState(false)
    const [resp, setResp] = useState("")
    const [error, setError] = useState("")



    function _handleSubmit(e) {
        e.preventDefault();
        if (terms) {
            let email = document.getElementById("email").value;
            addToMailchimp(email) // listFields are optional if you are only capturing the email address.
                .then(data => {
                    // I recommend setting data to React state
                    // but you can do whatever you want (including ignoring this `then()` altogether)
                    console.log(data)
                    setResp(data.msg)
                })
                .catch(() => {
                    // unnecessary because Mailchimp only ever
                    // returns a 200 status code
                    // see below for how to handle errors
                })
        } else {
            setError("Αποδεχτείτε την πολιτική απορρήτου")
        }
    }


    return (
        <div className="py-16 m-auto text-center px-4">
            <h2 className="dk-title">
                <span>{servicesSectionName}</span>
                <span>{servicesTitle}</span>
            </h2>

            <p className="m-auto mt-4 lead-newsl" >{servicesDescription}</p>

            {resp === ""
                ?
                <React.Fragment>
                    <div className="pt-8 md:pt-16 flex m-auto" >
                        <input id="email" type="text" placeholder="email" className="email-field" />
                        <button className="subs-bttn" onClick={(e) => _handleSubmit(e)}>
                            {servicesReadMore}
                        </button>
                    </div>
                    {
                        error !== "" && (
                            <p className='text-red-600'>{error}</p>
                        )
                    }

                    <div className="text-left pt-2">
                        <input type="checkbox" checked={terms} onClick={() => setTerms(!terms)} />
                        <span className="pl-2 terms-link">
                            <Link to={lang === "el" ? "/oroi-xrisis" : "/en/oroi-xrisis-en"}>
                                {contactTerms}
                            </Link>
                        </span>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className='mt-8'>
                        <h3><div dangerouslySetInnerHTML={{ __html: resp }} /></h3>
                    </div>
                </React.Fragment>
            }

        </div>
    );
}

export default Newsletter;

