import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import CoreValues from '../components/Home/CoreValues'
import AboutUs from '../components/Home/AboutUs'


import WorkProcess from '../components/Home/WorkProcess'
import HomeCarousel from '../components/Home/HomeCarousel'
import Contribution from '../components/Home/Contribution'
import OurBlog from '../components/Home/OurBlog'

import Newsletter from '../components/Home/Newsletter'

function HomeTemplate(props) {
    console.log(props)
    let data = props.data;

    console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            recentPosts={data.allWpPost}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
        >
            <div>
                <section>
                    <HomeCarousel data={data.allWpPage.nodes[0].homePageAcf.carousel} />
                    {/* <Introduction data={data.allWpPage.nodes[0].homePageAcf} /> */}
                </section>
                <section>
                    <AboutUs data={data.allWpPage.nodes[0].homePageAcf.whoWeAre} lang={props.pageContext.language} />
                </section>
                <section style={{ backgroundColor: "#F8F9FA" }}>
                    <Contribution data={data.allWpPage.nodes[0].homePageAcf.contribution} lang={props.pageContext.language} />
                </section>
                <section>
                    <OurBlog data={data.recent} lang={props.pageContext.language} />
                </section>
                <section>
                    <CoreValues data={data.allWpPage.nodes[0].homePageAcf.ourAction} />
                </section>

                <section style={{ backgroundColor: "#F8F4F0" }}>
                    <WorkProcess
                        data={data.allWpPage.nodes[0].homePageAcf.workProcess}
                    />
                </section>

                <section className="container-cs m-auto">
                    <Newsletter
                        servicesTitle={data.allWpPage.nodes[0].homePageAcf.servicesTitle}
                        servicesSectionName={data.allWpPage.nodes[0].homePageAcf.servicesSectionName}
                        servicesDescription={data.allWpPage.nodes[0].homePageAcf.servicesDescription}
                        servicesReadMore={data.allWpPage.nodes[0].homePageAcf.servicesReadMore}
                        contactTerms={data.allWpPage.nodes[0].homePageAcf.contactTerms}
                        lang={props.pageContext.language}
                    />
                </section>




                {/* <section>
                    <OurClients data={{ title: data.allWpPage.nodes[0].homePageAcf.ourClientsTitle, clients: data.clients.edges }} />
                </section>

                <section>
                    <ContactForm
                        form={data.template.contactInformationTemplate.form}
                        responses={data.template.contactInformationTemplate.responseMessages}
                        endPoint={"contactEmail"}
                        language={props.pageContext.language}
                        subjectRequired={true}
                        messageRequired={true}
                    />
                </section> */}
            </div>
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`query GET_PAGE($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      homePageAcf {
        ...HomeCarouselFragment
        ...OurActionFragment
        ...AboutUsFragment
        ...ContributionFragment
        servicesTitle
        servicesSectionName
        servicesDescription
        servicesReadMore
        contactTerms
        workProcess {
          sectionName
          title
          description
          workProcesses {
            title
            description
            icon {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 128, quality: 100, placeholder: NONE, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }

    }

    allWpClient(filter: {language: {code: {eq: $language}}}) {
      ...ClientsFragment
    }
    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }

    recent: allWpPost(limit: 3,filter: {language: {code: {eq: $language}}}) {
        nodes {
          title
          slug
          date
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                  }
              } 
            }
          }
          categories{
              nodes{
                  name
                  slug
                }
              }
          }
    }
}
`
